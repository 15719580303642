const NotificationUtils = require('./utils/NotificationUtils');

//create a class and return the instance
class LogCreator {
    constructor() {
        this.log = {
            changes: [],
        };
        this.allowedEventTypes = [
            'create',
            'update',
            'delete'
        ]
        this.NotAllowedTypeOfChanges = [
            'object', 
            'array', 
            'function'
        ];
    }
    /**
     * @description Set the admin data
     * @param {object} admin 
     * @returns {boolean}
     * @throws {Error}
     */
    setAdmin(admin) {
        //check if admin.uid and admin.displayName exists, if not, throw   a new error
        if (!admin.uid) {
            throw new Error('Missing admin uid or admin name');
        }
        //Save the admin data to the instance
        this.log.admin = {
            id: admin.uid,
            name: admin.displayName ? admin.displayName : 'N/A',        
        }
    }
    /**
     * @description Validate the event type
     * @param {string} type 
     * @returns {boolean}
     * @throws {Error}
     */
    validateEventType(type) {
        //check if type is a string, if not, throw a new error
        if (typeof type !== 'string') {
            throw new Error('Event type must be a string');
        }
        //check if type is in the list of allowed types, if not, throw a new error
        if (!this.allowedEventTypes.includes(type)) {
            throw new Error('Event type must be one of the following: create, update, delete');
        }
    }
    /**
     * @description Validate the event collection
     * @param {string} collection
     * @returns {boolean}
     * @throws {Error}
    */
    validateEventCollection(collection) {
        //check if collection is a string, if not, throw a new error
        if (typeof collection !== 'string') {
            throw new Error('Event collection must be a string');
        }
        //check if collection has not spaces or special characters, if not, throw a new error
        if (!collection.match(/^[a-zA-Z0-9]+$/)) {
            throw new Error('Event collection must be a string without spaces or special characters');
        }
    }
    /**
     * @description Set the event data
     * @param {string} type
     * @param {string} collection
     * @returns {boolean}
     * @throws {Error}
     */
    setEvent(type, collection) {
        //check if event bpth type and collection exists, if not, throw a new error
        if (!type || !collection) {
            throw new Error('Missing event type or collection');
        }
        //validate the event type
        this.validateEventType(type);
        //validate the event collection
        this.validateEventCollection(collection);
        //Save the event data to the instance
        this.log.event = {
            type: type,
            target: collection
        }
    }

    /**
     * @description Append the changes to the instance
     * @param {string} target 
     * @param {string} from 
     * @param {string} to 
     * @returns {boolean}
     * @throws {Error}
     */
    addChanges(target, from, to) {
        //check if target exists, if not, throw a new error
        if (!target) {
            throw new Error('Missing target');
        }
        //check event type exists, if not, throw a new error
        if (!this.log.event) {
            throw new Error('Missing event type');
        }
        //check if the event type is create or update, if yes, check if from and to exists, if not, throw a new error
        if (this.log.event.type === 'create') {
            if (!to) {
                throw new Error('Missing "to" key');
            }
        } else if (this.log.event.type === 'update') {
            if (!from || !to) {
                throw new Error('Missing "from" or "to" key');
            }
        }
        //push current changes to the instance
        this.log.changes.push({
            target: target,
            from: from,
            to: to
        })
    }
    /**
     * @description Itinerate through the log and check if is everything ok
     * @returns {boolean}
     * @throws {Error}
    */
    validate() {
         //=================================
        //check if admin exists, if not, throw a new error
        if (!this.log.admin) {
            throw new Error('[Admin] - Missing admin');
        }
        //check if admin.id and admin.name exists, if not, throw a new error
        if (!this.log.admin.id || !this.log.admin.name) {
            throw new Error('[Admin] - Missing admin id or admin name');
        }
         //=================================
        //check if event exists, if not, throw a new error
        if (!this.log.event) {
            throw new Error('[Event] - Missing event');
        }
        //check if event.type and event.target exists, if not, throw a new error
        if (!this.log.event.type || !this.log.event.target) {
            throw new Error('[Event] - Missing event type or event target');
        }

        if(this.log.event.type == 'create'){
                //check if changes exists and if is greater than 0, if not, throw a new error
                if (!this.log.changes || this.log.changes.length === 0) {
                throw new Error('[Changes] - Missing changes');
            }
        }
        //=================================
        //use for of loop to check if all changes have target, from and to
        for (const change of this.log.changes) {
            //check if change.target exists, if not, throw a new error
            if (!change.target) {
                throw new Error('[Changes] - Missing change target');
            }
            //check if change.from exists, if not, throw a new error
            if (!change.to) {
                throw new Error('[Changes] - Missing change from');
            }
        }
        //=================================
        //check if area exists, if not, throw a new error
        if (!this.log.area) {
            throw new Error('[Object] - Missing area');
        }
        //check if createdAt and updatedAt exists, if not, throw a new error
        if (!this.log.createdAt || !this.log.updatedAt) {
            throw new Error('[Object] - Missing createdAt or updatedAt');
        }
      //=================================
        //itinerate through the changes, save the keys that has stringfied objects and push to theOnes array
        for (const change of this.log.changes) {
            if (typeof change.to === 'string' && typeof change.from === 'string') {
                try {
                    let to = JSON.parse(change.to)
                    let from = JSON.parse(change.from)
                    //delete the keys that are the same in both objects
                    for (const key in to) {
                        if (to[key] === from[key]) {
                            delete to[key]
                            delete from[key]
                        }
                    }
                    //save in this.log.changes the new values
                    change.to = to
                    change.from = from
                }catch (e) {
                    //Is not a stringfied object
                }
            }
        }   
        //=================================
        return true;
    }
    /**
     * @description Itinerate through the changes and check if is everything ok, if is in the allowedTypeOfChanges array, if not format the value
     * @param {*} value 
     * @returns {object}
     */
    FilterTypeOfChange(value) {
        //check if value has a type that is in the allowedTypeOfChanges array, if not, throw a new error
        if (this.NotAllowedTypeOfChanges.includes(typeof value) && value !== null) {
            //cria um novo array com o conteúdo do value,  mas organizado as keys em ordem alfabética
            let keys = Object.keys(value).sort();
            //cria um novo array com o conteúdo do value,  mas organizado as keys em ordem alfabética
            let sortedObj = {};
            //itinerate through the keys and push the values to the sortedObj
            keys.forEach(function (key) {
                sortedObj[key] = value[key];
            });
            //the keys inside the keys need to be sorted too
            for (const [key, value] of Object.entries(sortedObj)) {
                if (typeof value === 'object') {
                    sortedObj[key] = this.FilterTypeOfChange(value);
                }   
            }
            //return the sortedObj as JSON
            return JSON.stringify(sortedObj);
        }
        return value;
    }
    /**
     * @description Compare the changes between the database values and the current values
     * @param {*} databaseValues - the values from the database
     * @param {*} currentValues -  the current values
     * @param {*} keysToCompare - if false, it will compare all the keys
     * @returns {array/object}
     */
    compareChanges(databaseValues, currentValues, keysToCompare) {
        let changes = [];
        if(keysToCompare == false){
            keysToCompare = Object.keys(databaseValues);
        }
        //itinerate through the current values and check if the value is different from the database value
        for (const [key, value] of Object.entries(currentValues)) {
            if (keysToCompare && keysToCompare.includes(key) ) {
                let dValue = this.FilterTypeOfChange(databaseValues[key]);
                let cValue = this.FilterTypeOfChange(value);
                //Convert values to JSON and compare them (Because the values can be objects, arrays or functions)
                if (cValue != dValue) {
                    //if from is object, array or function, return as stringify
                    changes.push({
                        target: key,
                        from: dValue,
                        to: cValue
                    })
                }
            }
            else if(this.log.event.type == 'create'){
                changes.push({
                    target: key,
                    from: '',
                    to: value
                })
            }
        }
        return changes;
    }
    /**
     * @description this functions will be used to compare the updates from two different logs
     * @param {object} currentValues - the current values of the object
     * @param {object} databaseValues - the values of the object from the database
     * @param {array} keysToCompare - the keys to compare (OPTIONAL)
     * @returns {object}
    */
    setChanges(currentValues, databaseValues, keysToCompare = false){
        switch (this.log.event.type) {
            case 'create':
                this.log.changes = this.compareChanges([], currentValues);
                break;
            case 'update':
                this.log.changes = this.compareChanges(databaseValues, currentValues, keysToCompare);
                break;
            case 'delete':
                this.log.changes = [];
                break;
            default:
                this.log.changes = [];
                break;
        }
    }
    clearLog(){
        this.log = {
            changes: [],
        };
    }
    /**
     * @description Validate and return the log
     * @param {*} area 
     * @returns {object}
     */
    create (area, timestamp){  
        //check if area exists, if not, throw a new error
        if (!area) {
            throw new Error('Missing area');
        }
        //append area, createdAt and updatedAt to the instance
        this.log.area = area;
        this.log.createdAt = timestamp;
        this.log.updatedAt = timestamp;
        //validate the log
        this.validate();
        //return the log
        return this.log;
    }

    createNotification(type){
        //Create new notification
        let notification = new NotificationUtils();
        /**
         * @title Notification Object Setup
         * @example {json} Request Body}
         * {
         * "name": "New User",
         * "type": "System",
         * "details": {
         *   "action": "/users/3532",
         *   "author": "System",
         *   "text": "users@tests.com created"
         * }
         */
        try {
        //capitalize  this.log.event.type
        let capitalized = this.log.event.type.charAt(0).toUpperCase() + this.log.event.type.slice(1);
        //Setup name of notification
        notification.name = capitalized + ' - ' + this.log.area;
        /**
         * @title Set type of notification
         * @type {string}
         * @example {string} 'System','User', "Organization", "Editor"
         */
        notification.type = type
        notification.action =   this.log.area + '/' + this.log.event.target;
        notification.author = this.log.admin.id;
        notification.text =  "HistoryLogs Triggered From - " + this.log.area;
        notification.creationDate =  this.log.createdAt;

        return notification.object;
        } catch (error) {
        res.status(500).send({success: false, message: error});
        }
    }
}

export default new LogCreator();